/* App.css */
@import url(https://fonts.googleapis.com/css?family=Montserrat);
/* Global styles */
html,body {
  margin: 0;
  padding: 0;
  font-family: 'Ink Free', sans-serif;
  background-color: #222;
  color: #ffffff;
  background-image: url('../images/home2.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
}

.container {
  max-width: 100%;
  height: 13vh;
  display: flex;
  display: -webkit-flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

/* Header styles */
header {
  background-color: #222;
  
  background-size: cover;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

header h1 {
  color: #ffffff;
  font-size: 64px;
  margin: 0;
  padding: 10px;
}

header h2 {
  color: #ffffff;
  font-size: 64px;
  margin: 0;
  font: '', sans-serif;
}


header p {
  color: #ffffff;
  font-size: 40px;
  margin: 0;
  font: '', sans-serif;
  padding: 10px;
  font-family: 'Times New Roman', sans-serif;
}

nav {
  margin-top: 20px;
}
svg {
  display: block;
  font: 10.5em 'Montserrat';
  width: 960px;
  height: 300px;
  margin: 0 auto;
}

.text-copy {
  fill: none;
  stroke: white;
  stroke-dasharray: 6% 29%;
  stroke-width: 5px;
  stroke-dashoffset: 0%;
  animation: stroke-offset 5.5s infinite linear;
}

.text-copy:nth-child(1){
stroke: #ffffff;
animation-delay: -1;
}

.text-copy:nth-child(2){
stroke: #a5c8ff;
animation-delay: -2s;
}

.text-copy:nth-child(3){
stroke: #5bfffa;
animation-delay: -3s;
}

.text-copy:nth-child(4){
stroke: #00e1ff;
animation-delay: -4s;
}

.text-copy:nth-child(5){
stroke: #31b6fd;
animation-delay: -5s;
}
/*type writer*/
@keyframes stroke-offset{
  100% { stroke-dashoffset: -45%; }
}

.twriter_container{
  place-content: center;
  width: 100%;
  display: grid;
}
.typewriter{
  width: 100%;
  align-items: center;
  justify-content: center;
}
/* DEMO-SPECIFIC STYLES */
.typewriter h1 {
  color: #fff;
  font-family: monospace;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange; /* The typewriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Centers the element horizontally */
  letter-spacing: .15em; /* Adjust as needed */
  text-align: center; /* Centers the text horizontally */
  animation: 
    typing 10s steps(80, end) infinite, /* Typing animation */
    blink-caret .5s step-end infinite; /* Cursor animation */
}

/* The typing effect */
@keyframes typing {
  0% { width: 0; }
  80% { width: 100%; }
  100% { width: 100%; animation-delay: 5s; }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  0%, 82% { border-color: transparent; }
  83%, 100% { border-color: orange; }
}

/* Adjust font size based on page size */
@media screen and (max-width: 600px) {
  .typewriter h1 {
    font-size: 16px;
  }
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
  .typewriter h1 {
    font-size: 24px;
  }
}

@media screen and (min-width: 1201px) {
  .typewriter h1 {
    font-size: 36px;
  }
}

